import "./App.css";
import Routers from "./Routers/Router";
import { inject } from "@vercel/analytics";

function App() {
  // const lenis = useLenis(({ scroll }) => {
  //   // called every scroll
  // });
  inject();

  return (
      <div className="App">
        <Routers />
      </div>
  );
}

export default App;
