import React from "react";
import "./Events.scss";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import events_hero from "../Assets/events-hero-bg.png";
import banquet_1 from "../Assets/banquet_1.png";
import banquet_2 from "../Assets/banquet_2.png";
import banquet_3 from "../Assets/banquet_3.png";
import c_venue_1 from "../Assets/opal.png";
import c_venue_2 from "../Assets/citrine.png";
import c_venue_3 from "../Assets/onyx.png";

import events_divider from "../Assets/events-divider.svg";

const Events = () => {
  return (
    <div className="events-main">
      <Header />
      <div className="events-hero-bg">
        <img src={events_hero} alt="events background" />
        <h1>Organise your luxury celebration at Sportico</h1>
      </div>
      <div className="events-section-two">
        <p>
          At Sportico, we specialize in crafting unforgettable moments for
          weddings, social events, and corporate meetings. Our versatile spaces
          and dedicated team ensure every occasion is memorable. From family
          gatherings to corporate retreats, Sportico offers a perfect blend of
          adventure, relaxation, and fun for everyone.
        </p>
      </div>
      <div className="events-section-three">
        <div className="banquets-main">
          <div className="banquet-item">
            <h1>Elegance</h1>
            <p>
              A refined space designed for sophistication and timeless
              celebrations. From weddings to corporate events, experience the
              perfect blend of style with top-class modern furniture and a great
              ambience.
            </p>
            <p> Closed Banquet Hall</p>
            <p className="banquet-capacity">Max capacity 500 people</p>
            <img
              className="venue-img"
              src={banquet_1}
              alt="Corporate venue"
            />
            <divider className="banquet-divider"></divider>
            <h1>Aura</h1>
            <p>
              Step into the enchanting atmosphere of Aura Open Banquet Hall at
              Sportico. Designed to captivate, this space radiates charm,
              offering the perfect setting for your special occasions and
              celebrations.
            </p>
            <p> Open Banquet Hall</p>
            <p className="banquet-capacity">Max capacity 1000 people</p>
            <img
              className="venue-img"
              src={banquet_2}
              alt="Corporate venue"
            />
            <divider className="banquet-divider"></divider>
            <h1>The Green Banquet</h1>
            <p>
              Experience the spacious Green Banquet, embraced by lush greenery
              and surrounded by the resort's natural beauty. An ideal venue for
              grand celebrations and unforgettable events.
            </p>
            <p> Open Banquet Hall</p>
            <p className="banquet-capacity">Max capacity 2000 people</p>
            <img
              className="venue-img"
              src={banquet_3}
              alt="Corporate venue"
            />
          </div>
        </div>
      </div>

      <div className="events-section-four">
        <img
          className="events-divider"
          src={events_divider}
          alt="Diver background"
        />
        <h1>Corporate Venues</h1>
        <p>
          We redefine corporate events, offering state-of-the-art facilities,
          versatile spaces, and a dedicated team to elevate your business
          gatherings. From conferences to team-building retreats, our resort
          provides a perfect blend of professionalism and relaxation. Experience
          corporate events like never before, where every detail is crafted to
          inspire success and encourage connections.
        </p>
        <div className="corporate-venues-main">
          <div className="venue-item">
            <img
              className="venue-img"
              src={c_venue_1}
              alt="Corporate venue"
            />
            <p>opal</p>
          </div>
          <div className="venue-item">
            <img
              className="venue-img"
              src={c_venue_2}
              alt="Corporate venue"
            />
            <p>citrine</p>
          </div>
          <div className="venue-item">
            <img
              className="venue-img"
              src={c_venue_3}
              alt="Corporate venue"
            />
            <p>onyx</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Events;
