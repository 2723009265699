import React, { useState, useEffect } from "react";
import "./Footer.scss";
import footer_bg_1 from "../Assets/footer-bg-1.png";
import { FaXTwitter, FaLinkedinIn, FaYoutube, FaFacebookF} from "react-icons/fa6";
import { AiOutlineInstagram } from "react-icons/ai";
import header_logo from "../Assets/sportico-logo.svg";

const Footer = () => {
  const [year, setYear] = useState(new Date().getFullYear());

  useEffect(() => {
    setYear(new Date().getFullYear());
  }, []);

  return (
    <div className="main-footer">
      <div className="cta-footer">
        <img src={footer_bg_1} alt="Footer Background" />
        <div className="footer-cta-content">
          <h6>Plan your ultimate experience at Sportico today!</h6>
          <p>Book your stay now </p>
          <a href="tel:+91 9591374999" target="_blank" className="footer-cta">
            Call us +91 9591374999
          </a>
        </div>
      </div>

      <div className="footer-container">
        <section className="footer-bottom">
          <div className="footer-links">
            <img className="" src={header_logo} alt="logo" />
            <p>Escape, Explore, Experience</p>
          </div>
          <div className="footer-links">
            <h6>Latest updates on</h6>
            <ul>
              <li>
                <a
                  href="https://instagram.com/sportico_bangalore"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <AiOutlineInstagram className="footer-social-icons" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/p/Sportico-Bangalore-61553086478221/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebookF className="footer-social-icons" />
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/sportico_blr"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaXTwitter className="footer-social-icons" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/in/sportico-bangalore-50907929a"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedinIn className="footer-social-icons" />
                </a>
              </li>
              <li>
                <a
                  href="https://youtube.com/@SporticoResorts"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaYoutube className="footer-social-icons" />
                </a>
              </li>
            </ul>
          </div>
          <div className="footer-links">
            <h6>We are here</h6>
            <p>
              Sportico-Luxury resort, Adventure Sports & Spa Near Nisarga
              vision city, Sithanayakalli, Malure Taluk , Kolar(Dist.),
              Karnataka-563130
            </p>
          </div>
        </section>
        <span className="divider" />
        <section className="footer-cprt">
          <p>&copy; {year}, Sportico. All rights reserved </p>
          <a>
            Design and Developed by FusionLOOM
          </a>
        </section>
      </div>
    </div>
  );
};
export default Footer;
