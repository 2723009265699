import React from "react";
import "./Rooms.scss";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import rooms_bg from "../Assets/rooms-bg.png";
import room_1 from "../Assets/room-1.png";
import room_2 from "../Assets/Suite-room.png";


const Rooms = () => {
  return (
    <div className="main-container">
      <Header />
      <div className="rooms-bg-hero">
        <img src={rooms_bg} alt="Rooms Hero Header Background" />
        <h1>
          Luxurious accomodations designed for ultimate comfort and relaxation
        </h1>
      </div>

      {/* Rooms Description */}

      <div className="rooms-des">
        <h1>Elevate Your Experience where Elegance Meets Adventure</h1>
        <p>
          Sportico features 9 lavish suites and 35 superior rooms where luxury
          meets every guest's satisfaction. Our rooms are designed with elegant
          decor and premium facilities to fulfill your every need. We ensure you
          a stay that you will remember forever in your memories.
        </p>
      </div>

      {/* Room Types */}

      <div className="room-category-container">
        <div className="room-type">
          <img src={room_1} alt="Type of room in the resort" />
        </div>
        <div className="room-details-main">
          <div className="room-details">
            <h1>Superior Room</h1>
            <span>from INR 5999 / person / night</span>
            <p>
              Escape into superior comfort, where every detail is crafted for
              your pleasure
            </p>
          </div>
        </div>
      </div>
      <div className="room-category-container">
        <div className="room-type">
          <img src={room_2} alt="Type of room in the resort" />
        </div>
        <div className="room-details-main">
          <div className="room-details">
            <h1>Suite Room</h1>
            <span>from INR 7999 / person / night</span>
            <p>
              Elevate your stay with a lavish suite experience, capture
              beautiful views of the resort in spacious balcony.
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};
export default Rooms;
