import React from "react";
import "./Home.scss";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import HomeGallery from "../HomeGallery/HomeGallery";
import home_bg from "../Assets/home_bg.png";
import sec_2_img from "../Assets/img-sec-2.png";
//Rooms
import suite1 from "../Assets/suite-1.png";
import suite2 from "../Assets/suite-2.png";
// Amenities
import amenity_1 from "../Assets/Amenity-1.png";
import amenity_2 from "../Assets/Amenity-2.png";
import amenity_3 from "../Assets/Amenity-3.png";
import amenity_4 from "../Assets/Amenity-4.png";
import amenity_5 from "../Assets/Amenity-5.png";
 

//Sports
import sport1 from "../Assets/Tree-climbing.png";
import sport2 from "../Assets/Dirt-Kart.png";
import sport3 from "../Assets/volley-ball.png";
import sport4 from "../Assets/Kayaking.png";
import sport5 from "../Assets/Paintball.png";

const Home = () => {
  return (
    <div className="main-container">
      <Header />
      <div className="hero-section">
        <section className="home-banner">
          <div className="bg-img">
            <img src={home_bg} alt="home background" />
          </div>

          <div className="home-tag-line">
            <p>Escape</p>
            <p>Explore</p>
            <p>Experience</p>
          </div>
        </section>

        {/* <section className="main-offers">
          <h1 className="">Latest Offers and Packages</h1>
          <div className="offers-conatiner">
            <div className="package">
              <img src={day_out} alt="home background" />
              <h6>DAY OUT</h6>
              <p>
                <span>Included</span> - Welcome drink, buffet veg and non-veg
                Lunch, Hi tea, pool access, Common indoor games, Fitness centre
                and access to all resort facilities
              </p>
            </div>
             <div className="package">
              <img src={corporate} alt="home background" />
              <h6>Corporate Team - from RS 1299</h6>
              <p>
                <span>Included</span> - Welcome drink, Lunch, Team building
                activities, Common games, Indoor games, Fitness center, Hi tea
              </p>
            </div> 
            <div className="package">
              <img src={day_night} alt="home background" />
              <h6>Superior Stay</h6>
              <p>
                <span>Included</span> - Welcome drink, buffet veg and non-veg
                Lunch, Hi tea, pool access,Indoor & Outdoor Games, Fitness
                centre and access to all resort facilities
              </p>
            </div>
            <div className="package">
              <img src={weekend} alt="home background" />
              <h6>Suite Stay</h6>
              <p>
                <span>Included</span> - Welcome drink, buffet veg and non-veg
                Lunch, Hi tea, pool access,Indoor & Outdoor Games, Fitness
                centre and access to all resort facilities
              </p>
            </div>
          </div>
        </section> */}
      </div>

      {/* Section About */}

      <div className="section-about">
        <section className="content-container">
          <h1 className="">
            Elevate Your Experience: Where Elegance Meets Adventure
          </h1>
          <p className="">
            Welcome to Sportico Bangalore Resort, where we redefine the concept
            of luxury and leisure. Imagine a place where every detail is crafted
            to meet your highest expectations. From our opulent wedding and
            corporate venues to adrenaline-pumping adventure sports, we offer a
            comprehensive experience that caters to all your needs. This isn't
            just a stay, it's an experience you'll cherish forever.
          </p>
          <p className="">
            Surrounded by scenic beauty and attractive tourist attractions we
            make it convenient for you to visit all the beautiful places with
            our inhouse guide. We can also help you arrange transport facilities
            for an easier commute to nearby places.
          </p>
        </section>
        <section className="img-conatiner">
          <img src={sec_2_img} alt="Pool View of the Resort" />
        </section>
      </div>

      {/* Section Sports */}

      <div className="section-sports">
        <h1 className="">Adventure Sports</h1>
        <section>
          <div className="sport">
            <img src={sport1} alt="Sapphire Suite" />
            <h6>Tree Climbing</h6>
          </div>
          <div className="sport">
            <img src={sport2} alt="Sapphire Suite" />
            <h6>Dirt Kart</h6>
          </div>
          <div className="sport">
            <img src={sport3} alt="Sapphire Suite" />
            <h6>Volley Ball</h6>
          </div>
          <div className="sport">
            <img src={sport4} alt="Sapphire Suite" />
            <h6>Kayaking</h6>
          </div>
          <div className="sport">
            <img src={sport5} alt="Sapphire Suite" />
            <h6>Paintball</h6>
          </div>
        </section>
        <a href="/adventure" className="section-cta">
          View more
        </a>
      </div>
      {/* Section Rooms */}

      <div className="section-rooms">
        <section className="content-container">
          <h1 className="">Premium Suites</h1>
        </section>
        <section className="img-conatiner">
          <a href="/rooms" className="suite">
            <img src={suite1} alt="Sapphire Suite" />
            <div className="suite-content">
              <h6>Superior</h6>
              <p>Cozy yet luxurious, perfect for a quick getaway</p>
            </div>
          </a>
          <a href="/rooms" className="suite">
            <img src={suite2} alt="Aqua Suite" />
            <div className="suite-content">
              <h6>Suite</h6>
              <p>Unwind in our suite. Ultimate comfort and elegance await. </p>
            </div>
          </a>
        </section>
      </div>

      {/* Section Amenities */}
      <div className="section-amenities">
        <div className="amenities-container">
          <section className="left-amenities-grp">
            <div className="amenity">
              <img src={amenity_1} alt="Sapphire Suite" />
              <p>Banquet Hall</p>
            </div>
            <div className="amenity">
              <img src={amenity_2} alt="Sapphire Suite" />
              <p>SPA - Mandala</p>
            </div>
            <div className="amenity">
              <img src={amenity_3} alt="Sapphire Suite" />
              <p>Mist Rooftop Bar</p>
            </div>
            <div className="amenity">
              <img src={amenity_4} alt="Sapphire Suite" />
              <p>Saffron restaurant</p>
            </div>
          </section>
          <section className="">
            <h1 className="">Amenities & Services</h1>
            <div className="amenity amenity-5">
              <img src={amenity_5} alt="Sapphire Suite" />
              <p>Frequency pub</p>
            </div>
          </section>
        </div>
        <a href="/amenities" className="section-cta">View more</a>
      </div>

      <HomeGallery />

      <Footer />
    </div>
  );
};
export default Home;
