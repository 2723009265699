import React from "react";
import "./Adventure.scss";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import adventure_bg from "../Assets/adventure-bg.png";


const Adventure = () => {
  return (
    <div className="main-container">
      <Header />
      <div className="bg-img">
            <img src={adventure_bg} alt="adventure background" />
          </div>

    </div>
  );
};
export default Adventure;
