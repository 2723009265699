import React from "react";
import "./Amenities.scss";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import amenities_hero from "../Assets/amenities-hero.png";
import spa_hero from "../Assets/spa-hero.png";
import pub_hero from "../Assets/pub-hero.png";
import bar_hero from "../Assets/bar-hero.png";

import feature1 from "../Assets/feature01.png";
import feature2 from "../Assets/feature02.png";
import feature3 from "../Assets/feature03.png";
import feature4 from "../Assets/feature04.png";
import other_amenity_1 from "../Assets/other-amenity-1.png";
import other_amenity_2 from "../Assets/other-amenity-2.png";
import other_amenity_3 from "../Assets/other-amenity-3.png";
import resto_1 from "../Assets/resto-img-1.png";
import resto_2 from "../Assets/resto-img-2.png";
import resto_3 from "../Assets/resto-img-3.png";

import therapies from "../Assets/Therapists.svg";
import hygiene from "../Assets/hygiene.svg";
import quality from "../Assets/quality-products.svg";
import researched from "../Assets/researched-therapies.svg";

import gallery_divider from "../Assets/Divider.svg";

const Amenities = () => {
  return (
    <div className="amenities-main">
      <Header />
      <div className="amenities-hero-bg">
        <img src={amenities_hero} alt="amenities background" />
        <h1>Luxury inclusive amenities and services</h1>
      </div>
      <div className="spa-section">
        <div className="spa-header">
          <p className="amenity-title">spa</p>
          <h1 className="amenity-heading">Mandala</h1>
          <p className="amenity-desc">
            Melt away stress and indulge in pure bliss at our world-class spa.
            From rejuvenating massages and facials to aromatherapy and
            hydrotherapy treatments, our expert therapists will tailor a journey
            to your unique needs.
          </p>
          <div className="spa-hero-img">
            <img src={spa_hero} alt="amenities background" />
          </div>
          <div className="spa-offerings">
            <div className="promise">
              <img src={therapies} alt="spa offerings" className="type" />
              <p>Certified Therapists</p>
            </div>

            <div className="promise">
              <img src={hygiene} alt="spa offerings" className="type" />

              <p>Commitment to Hygiene</p>
            </div>

            <div className="promise">
              <img src={quality} alt="spa offerings" className="type" />

              <p>Quality Products</p>
            </div>

            <div className="promise">
              <img src={researched} alt="spa offerings" className="type" />

              <p>Researched Therapies</p>
            </div>
          </div>
          <div className="spa-features">
            <div className="feature">
              <div className="feature-img-1">
                <img src={feature1} alt="amenities background" />
              </div>
              <p>Body Massages and facials</p>
            </div>

            <div className="feature">
              <div className="feature-img-2">
                <img src={feature2} alt="amenities background" />
              </div>
              <p>Salt & steam room</p>
            </div>

            <div className="feature">
              <div className="feature-img-2">
                <img src={feature3} alt="amenities background" />
              </div>
              <p>Body Scrubs & Wraps</p>
            </div>

            <div className="feature">
              <div className="feature-img-1">
                <img src={feature4} alt="amenities background" />
              </div>
              <p>Medicure & Pedicure</p>
            </div>
          </div>
        </div>
      </div>
      <div className="pub-section">
        <img
          className="pub-divider"
          src={gallery_divider}
          alt="Diver background"
        />

        <div className="pub-header">
          <div className="amenity-heading-main">
            <p className="amenity-title">Pub</p>
            <h1 className="amenity-heading">Frequency</h1>
          </div>
          <div>
            <p className="amenity-desc">
              Explore Bangalore's best cocktail pub, The Frequency at Sportico
              is a trendy hotspot brings a global bar culture to Bangalore, with
              its vibrant decor and diverse menu.
            </p>
          </div>
        </div>
        <div className="pub-hero-img">
          <img src={pub_hero} alt="amenities background" />
        </div>
        <p className="amenity-desc">
          Ready to Dance on our biggest dance floor, with DJ beats that blend
          Indian and Hip Hop, creating a night to remember . Sportico Frequency
          Pub is perfect place for party freaks and one of the most famous night
          clubs in Bangalore.
        </p>
      </div>

      <div className="bar-section">
        <div className="bar-hero-img">
          <img src={bar_hero} alt="amenities background" />
        </div>

        <div className="bar-header">
          <p className="amenity-title">RESTO-BAR</p>
          <h1 className="amenity-heading">Mist roof top</h1>
          <p className="amenity-desc">
            Elevate your evenings at Mist Rooftop Bar, one of the largest and
            premium rooftop bars near Bangalore. Immerse yourself in a
            sophisticated ambiance, complete with premium drinks and handcrafted
            cocktails. Whether it's a romantic date or a night out with friends,
            Mist Rooftop Bar offers the perfect destination for a relaxing
            evening.
          </p>
        </div>
        <img
          className="bar-divider"
          src={gallery_divider}
          alt="Diver background"
        />
      </div>

      <div className="resto-section">
        <div className="resto-header">
          <p className="amenity-title">Restaurant</p>
          <h1 className="amenity-heading">Saffron</h1>
          <p className="amenity-desc">
            Savor the diverse culinary delights at Sportico luxury resort near
            Bangalore. Experience Indian, Chinese, and Continental cuisines with
            vegetarian and non-vegetarian buffet options in our Saffron
            restaurant. Our expert chefs craft each dish into a masterpiece,
            providing the ultimate dining experience.
          </p>
        </div>
        <div className="resto-img-main">
          <div className="">
            <img src={resto_1} alt="amenities background" />
            <img src={resto_2} alt="amenities background" />
          </div>
          <div className="">
            <img src={resto_3} alt="amenities background" />
          </div>
        </div>
      </div>

      <div className="other-amenities-section">
        <h1 className="amenity-heading">
          amenities designed to cater to your every desire
        </h1>

        <div className="other-amenities">
          <div className="amenity">
            <img src={other_amenity_1} alt="amenitiy image" />
            <h6>Pool Party</h6>
            <p>
              Splash into moments of ecstasy at the biggest pool party in
              Bangalore! Join us for the high-energy vibes at the best luxury
              resort in Bangalore.
            </p>
          </div>

          <div className="amenity">
            <img src={other_amenity_2} alt="amenitiy image" />
            <h6>Dhvani</h6>
            <p>
              Our open-air amphitheatre, hosting diverse events from yoga
              sessions to intimate musical gatherings, plays, and meetings.
              Experience the events under the open sky at Sportico Bangalore.
            </p>
          </div>

          <div className="amenity">
            <img src={other_amenity_3} alt="amenitiy image" />
            <h6>Fitness Center</h6>
            <p>
              Elevate your well-being with Sportico's wellness offerings. Our
              well-equipped fitness center equipped with modern facilities
              ensuring you Stay active and healthy!s
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};
export default Amenities;
