import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../Home/Home";
import Adventure from "../Adventure/Adventure";
import Rooms from "../Rooms/Rooms";
import About from "../About/About";
import Events from "../Events/Events";
import Amenities from "../Amenities/Amenities";



const Routers = () => {
  return (
    <div>
      <BrowserRouter basename="/">
        <Routes>
          <Route exact path="/rooms" element={<Rooms />} />

          <Route exact path="/" element={<Home />} />
          <Route exact path="/adventure" element={<Adventure />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/events" element={<Events />} />
          <Route exact path="/amenities" element={<Amenities />} />



          {/* <Route path="*" element={<PageNotFound />} /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default Routers;
